import React, { useEffect } from "react";
import { Facet } from "@elastic/react-search-ui";
import { MultiCheckboxFacet } from "@elastic/react-search-ui-views";
import { LocationSelection } from "./LocationSelection";
import { StartdateSelection } from "./StartdateSelection";
import { LevelSelection } from "./LevelSelection";
import { MiscellaneousSelection } from "./MiscellaneousSelection";
import { GroupSelection } from "./GroupSelection";
import dayjs from "dayjs";

export const Filter = ({
  geolocationSortingObject,
  switchToUserGeolocationSorting,
  switchToCityGeolocationSorting,
  disableGeolocationSorting,
  locationCheckboxValue,
  setLocationCheckboxValue,
  autocompleteValue,
  setAutocompleteValue,
  startDate,
  setStartDate,
  addFilter,
  filters,
  removeFilter,
  selectedLevel,
  setSelectedLevel,
  selectedAvailability,
  setSelectedAvailability,
  selectedGuarantee,
  setSelectedGuarantee,
  filterType,
  filterValue,
  setFilter,
  filterIsInit,
  setFilterIsInit,
  searchState,
  setSearchState,
  setSort,
  setSearchTerm,
  selectedIndividualTraining,
  setSelectedIndividualTraining,
  selectedGroupTraining,
  setSelectedGroupTraining,
  updateCenterAndFacets,
}) => {
  useEffect(() => {
    const useStartDate = startDate && startDate.length > 0;
    let targetDate = "";

    if (useStartDate) {
      // only seminars in -2 to +2 weeks from selected startdate
      targetDate = dayjs(startDate, "YYYY-MM-DD")
        .hour(12)
        .minute(0)
        .second(0)
        .millisecond(0);
      const from = targetDate.subtract(2, "weeks").format("YYYY-MM-DD");
      const to = targetDate.add(2, "weeks").format("YYYY-MM-DD");
      setFilter(
        "start_date",
        {
          from: from,
          to: to,
          name: "start_date_range",
        },
        "any"
      );
    } else {
      removeFilter("start_date");
    }

    const useLocation =
      geolocationSortingObject &&
      geolocationSortingObject.mode !== "none" &&
      geolocationSortingObject.latitude &&
      geolocationSortingObject.longitude;

    // if (useLocation) {
    //   // only seminars in < 300km distance
    //   setFilter("coordinates", "In der Nähe", "any");
    // } else {
    //   removeFilter("coordinates");
    // }

    const scriptWithLocation = `
    boolean isVeryClose = false;
    double distanceScore = 0;
    boolean isOnline = false;
    if (doc['coordinates'].size() == 0 || doc['coordinates'] == null || doc['coordinates'].lat == null || doc['coordinates'].lon == null) {
      isVeryClose = false;
      distanceScore = 0;
      isOnline = true;
    }
    else {
      double lat1 = doc['coordinates'].lat;
      double lon1 = doc['coordinates'].lon;
      double lat2 = params.centerLat;
      double lon2 = params.centerLon;
      double dLat = Math.toRadians(lat2 - lat1);
      double dLon = Math.toRadians(lon2 - lon1);
      double a = Math.sin(dLat/2) * Math.sin(dLat/2) + 
        Math.cos(Math.toRadians(lat1)) * Math.cos(Math.toRadians(lat2)) * 
        Math.sin(dLon/2) * Math.sin(dLon/2);
      double c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
      double distanceInKm = 6371 * c;
      double minDistanceInKm = 0;
      double maxDistanceInKm = 300;
      double distanceInKmInRange = Math.max(Math.min(distanceInKm, maxDistanceInKm), minDistanceInKm);
      isVeryClose = (distanceInKmInRange <= 25);
      distanceScore = Math.abs(9 * (distanceInKmInRange / (maxDistanceInKm - minDistanceInKm)) - 9);
    }
  `;

    const scriptWithoutLocation = `
    boolean isVeryClose = false;
    double distanceScore = 0;
    boolean isOnline = false;
  `;

    const scriptWithStartDate = `
   double dateDiffInDays = Math.abs((doc['start_date'].value.toInstant().toEpochMilli() - params.targetDate) / 86400000);
    double minDateDiffInDays = 0;
    double maxDateDiffInDays = 14;
    double dateDiffInDaysInRange = Math.max(Math.min(dateDiffInDays, maxDateDiffInDays), minDateDiffInDays);
    boolean isSameDay = (dateDiffInDaysInRange == 0);
    double dateDiffScore = Math.abs(9 * (dateDiffInDaysInRange / (maxDateDiffInDays - minDateDiffInDays)) - 9);
  `;

    const scriptWithoutStartDate = `
     boolean isSameDay = false;
    double dateDiffScore = 0;
  `;

    // Common part of the script
    const commonScript = `
    double score = 0;
    if (isVeryClose && isSameDay) {
      score = 1000000;
    }
    if (isVeryClose) {
      score = score + 100000;
      score = score + dateDiffScore * 10000;
    }
    if (isOnline) {
      score = score + 1000;
      score = score + dateDiffScore * 100;
    }
    score = score + distanceScore * 10;
    score = score + dateDiffScore * 1;
    return score;
  `;

    // Combine the scripts based on useLocation
    let finalScript = "";
    if (useLocation) {
      finalScript = finalScript + scriptWithLocation;
    } else {
      finalScript = finalScript + scriptWithoutLocation;
    }
    if (useStartDate) {
      finalScript = finalScript + scriptWithStartDate;
    } else {
      finalScript = finalScript + scriptWithoutStartDate;
    }

    finalScript = finalScript + commonScript;

    /*
    A - Locations:
    ["Berlin", "Hamburg", "Köln", "Frankfurt", "Stuttgart", "Zürich", "München", "Wien", "Madrid"]
    */
    setSort([
      {
        field: "_score",
        direction: "desc",
      },
      {
        field: "_script",
        direction: {
          script: {
            source: finalScript,
            params: {
              targetDate: useStartDate ? targetDate.valueOf() : 0,
              centerLat: useLocation ? geolocationSortingObject.latitude : 0,
              centerLon: useLocation ? geolocationSortingObject.longitude : 0,
            },
            lang: "painless",
          },
          type: "number",
          order: "desc",
        },
      },
    ]);
  }, [startDate, geolocationSortingObject]);

  React.useEffect(() => {
    if (!filterIsInit) {
      // const targetDate = dayjs().hour(12).minute(0).second(0).millisecond(0);
      // setStartDate(targetDate.format("YYYY-MM-DD"));
      setFilterIsInit(true);
    }
  }, []);

  return (
    <div className="mi-kufer-filter-box-container">
      <div className="mi-kufer-filter-box-heading">Seminare filtern</div>
      <LocationSelection
        geolocationSortingObject={geolocationSortingObject}
        switchToUserGeolocationSorting={switchToUserGeolocationSorting}
        switchToCityGeolocationSorting={switchToCityGeolocationSorting}
        disableGeolocationSorting={disableGeolocationSorting}
        locationCheckboxValue={locationCheckboxValue}
        setLocationCheckboxValue={setLocationCheckboxValue}
        autocompleteValue={autocompleteValue}
        setAutocompleteValue={setAutocompleteValue}
        searchState={searchState}
        setSearchState={setSearchState}
        setSort={setSort}
      />

      {/* {geolocationSortingObject.mode !== "none" && (
        <Facet field="coordinates" label="Entfernung" filterType="any" />
      )} */}
      {/* <Facet field="coordinates" label="Entfernung" filterType="any" /> */}

      <StartdateSelection
        filters={filters}
        addFilter={addFilter}
        removeFilter={removeFilter}
        setFilter={setFilter}
        startDate={startDate}
        setStartDate={setStartDate}
        setSort={setSort}
        filterIsInit={filterIsInit}
        setFilterIsInit={setFilterIsInit}
        setSearchTerm={setSearchTerm}
      />

      <Facet field="duration" label="Dauer" view={MultiCheckboxFacet} />

      <Facet field="kurs_art" label="Kursart" view={MultiCheckboxFacet} />

      <LevelSelection
        filters={filters}
        addFilter={addFilter}
        removeFilter={removeFilter}
        setFilter={setFilter}
        selectedLevel={selectedLevel}
        setSelectedLevel={setSelectedLevel}
      />

      <GroupSelection
        filters={filters}
        addFilter={addFilter}
        removeFilter={removeFilter}
        setFilter={setFilter}
        selectedIndividualTraining={selectedIndividualTraining}
        setSelectedIndividualTraining={setSelectedIndividualTraining}
        selectedGroupTraining={selectedGroupTraining}
        setSelectedGroupTraining={setSelectedGroupTraining}
      />

      <MiscellaneousSelection
        filters={filters}
        addFilter={addFilter}
        removeFilter={removeFilter}
        setFilter={setFilter}
        selectedGuarantee={selectedGuarantee}
        setSelectedGuarantee={setSelectedGuarantee}
        selectedAvailability={selectedAvailability}
        setSelectedAvailability={setSelectedAvailability}
      />

      <button
        className="button-large-cyan"
        style={{ width: "100%", border: "none" }}
        onClick={(event) => {
          setStartDate(null);
          setLocationCheckboxValue(false);
          disableGeolocationSorting(setSort);
        }}
      >
        <p>FILTER ZURÜCKSETZEN</p>
      </button>

      {/* <Facet field="location" label="Location" view={MultiCheckboxFacet} />
      <Facet
        field="basic"
        label="Basic"
        view={MultiCheckboxFacet}
        filterValueTransformer={(value) => (value === "1" ? true : false)}
      />
      <Facet field="advanced" label="Advanced" view={MultiCheckboxFacet} />
      <Facet field="expert" label="Expert" view={MultiCheckboxFacet} /> */}
    </div>
  );
};
