import React from "react";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/de"; // Importiere die deutsche Lokalisierung
import isoWeek from "dayjs/plugin/isoWeek";
import updateLocale from "dayjs/plugin/updateLocale";
import { source } from "vfile-message";
import dayjs from "dayjs";

export const StartdateSelection = ({
  filters,
  addFilter,
  removeFilter,
  setFilter,
  startDate,
  setStartDate,
  setSort,
  filterIsInit,
  setFilterIsInit,
  setSearchTerm,
}) => {
  return (
    <fieldset className="sui-facet">
      <legend className="sui-facet__title">Startdatum</legend>
      <div className="sui-multi-checkbox-facet">
        <label className="sui-multi-checkbox-facet__option-label">
          <div
            className="sui-multi-checkbox-facet__option-input-wrapper"
            style={{ marginTop: "10px" }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
              <StaticDatePicker
                value={startDate ? dayjs(startDate) : dayjs()} // Heutiges Datum als Standardwert
                displayStaticWrapperAs="desktop"
                onChange={(newValue) => {
                  const selectedDate = dayjs(newValue)
                    .hour(12)
                    .minute(0)
                    .second(0)
                    .millisecond(0);
                  setStartDate(selectedDate.format("YYYY-MM-DD"));
                }}
              />
            </LocalizationProvider>
          </div>
        </label>
      </div>
    </fieldset>
  );
};
