import { set } from "lodash";
import React, { useEffect, useRef, useState } from "react";

export const LevelSelection = ({
  filters,
  addFilter,
  removeFilter,
  setFilter,
  selectedLevel,
  setSelectedLevel,
}) => {
  return (
    <fieldset className="sui-facet">
      <legend className="sui-facet__title">Stufe</legend>
      <div className="sui-multi-checkbox-facet">
        <div style={{ display: "flex", width: "100%" }}>
          <button
            className={
              selectedLevel === "basic"
                ? "filterSelectionButtonSelected"
                : "filterSelectionButton"
            }
            onClick={(event) => {
              removeFilter("basic", "true");
              removeFilter("advanced", "true");
              removeFilter("expert", "true");
              if (selectedLevel === "basic") {
                setSelectedLevel("");
              } else {
                setSelectedLevel("basic");
                addFilter("basic", "true");
              }
            }}
          >
            <p>Basic</p>
          </button>
          <button
            className={
              selectedLevel === "advanced"
                ? "filterSelectionButtonSelected"
                : "filterSelectionButton"
            }
            onClick={(event) => {
              removeFilter("basic", "true");
              removeFilter("advanced", "true");
              removeFilter("expert", "true");
              if (selectedLevel === "advanced") {
                setSelectedLevel("");
              } else {
                setSelectedLevel("advanced");
                addFilter("advanced", "true");
              }
            }}
          >
            <p>Aufbau</p>
          </button>
          <button
            className={
              selectedLevel === "expert"
                ? "filterSelectionButtonSelected"
                : "filterSelectionButton"
            }
            onClick={(event) => {
              removeFilter("basic", "true");
              removeFilter("advanced", "true");
              removeFilter("expert", "true");
              if (selectedLevel === "expert") {
                setSelectedLevel("");
              } else {
                setSelectedLevel("expert");
                addFilter("expert", "true");
              }
            }}
          >
            <p>Expert</p>
          </button>
        </div>
      </div>
    </fieldset>
  );
};
