import React from "react";
import {
  getAvailabilityIcon,
  getGuaranteeIcon,
  getLevelIcon,
} from "./SearchResultController";

export const SearchResultBox = ({ result, displayProduct }) => {
  function formatDate(dateString) {
    // Split the date and time
    const [datePart] = dateString.split(" ");

    // Split the date part into year, month, and day
    const [year, month, day] = datePart.split("-");

    // Format the date in DD.MM.YYYY format
    return `${day}.${month}.${year}`;
  }

  return (
    <div id={result.sku.raw} className="mi-kufer-search-result-box-container">
      <div className="mi-kufer-search-result-box-container-top">
        <div className="mi-kufer-search-result-box-container-top-top">
          <div className="mi-kufer-search-result-box-container-top-icons-container">
            <div className="mi-kufer-search-result-box-container-top-icons-container-left">
              {!displayProduct && getAvailabilityIcon(result.availability.raw)}
              {!displayProduct && (
                <div className="mi-kufer-search-result-sku">
                  {result.sku.raw}
                </div>
              )}
              {!displayProduct && getGuaranteeIcon(result.guaranteed.raw)}
            </div>

            {getLevelIcon(
              result.basic.raw
                ? "1"
                : result.advanced.raw
                ? "2"
                : result.expert.raw
                ? "3"
                : "0"
            )}
          </div>
          <div className="mi-kufer-search-result-title">
            {result.kurzbez.raw}
          </div>
        </div>
        <div className="mi-kufer-search-result-box-container-top-center">
          {!displayProduct && (
            <div className="mi-kufer-search-result-box-container-top-center-info-container">
              <img
                className="mi-kufer-search-result-level"
                src="https://manager-institut.hppycdng.dev/wp-content/themes/manager-institut/theme/images/react-kufer-search/location.png"
                alt="open"
              />
              <div className="mi-kufer-search-result-box-container-top-center-info-text">
                <div className="result-gebuehr">{result.location.raw}</div>
              </div>
            </div>
          )}
          {!displayProduct && (
            <div className="mi-kufer-search-result-box-container-top-center-info-container">
              <img
                className="mi-kufer-search-result-level"
                src="https://manager-institut.hppycdng.dev/wp-content/themes/manager-institut/theme/images/react-kufer-search/calendar.png"
                alt="open"
              />
              <div className="mi-kufer-search-result-box-container-top-center-info-text">
                <div className="result-gebuehr">
                  {formatDate(result.start_date.raw)} • {result.duration.raw}
                </div>
              </div>
            </div>
          )}
          <div className="mi-kufer-search-result-box-container-top-center-info-container">
            {result.kurs_art.raw && (
              <div className="mi-kufer-search-result-box-container-top-center-info-text">
                <div className="mi-kufer-search-result-kursart">
                  {result.kurs_art.raw}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {result.availability.raw !== 2 ? (
        <a
          className="button-large-green mi-offer-button"
          style={{ width: "100%" }}
          href={
            result.url.raw
              ? displayProduct
                ? result.url.raw.replace(
                    `//#${result.url.raw.split("//#")[1]}`,
                    ""
                  )
                : result.url.raw.replace("//#", "/#")
              : ""
          }
        >
          <p>SEMINAR ENTDECKEN</p>
          <img
            style={{
              height: "12.015px",
              width: "10.498px",
              transform: "rotate(-90deg)",
            }}
            src="https://manager-institut.hppycdng.dev/wp-content/themes/manager-institut/theme/images/react-kufer-search/long-arrow-down-black.png"
            alt="open"
          />
        </a>
      ) : (
        <></>
      )}
    </div>
  );
};
