import React, { useEffect, useRef, useState } from "react";

export const LocationSelection = ({
  geolocationSortingObject,
  switchToUserGeolocationSorting,
  switchToCityGeolocationSorting,
  disableGeolocationSorting,
  locationCheckboxValue,
  setLocationCheckboxValue,
  autocompleteValue,
  setAutocompleteValue,
  searchState,
  setSearchState,
  setSort,
}) => {
  const autocompleteRef = useRef(null);

  useEffect(() => {
    if (!autocompleteRef.current) return;

    let autocomplete;

    if (window.google && window.google.maps && window.google.maps.places) {
      autocomplete = new window.google.maps.places.Autocomplete(
        autocompleteRef.current,
        {
          types: ["geocode"], // Beschränkt die Suche auf geografische Standorte
          componentRestrictions: { country: ["de", "ch", "at", "es"] }, // Beschränkt auf Deutschland, Schweiz, Österreich und Spanien
        }
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place.geometry) {
          const latitude = place.geometry.location.lat();
          const longitude = place.geometry.location.lng();
          setAutocompleteValue(place.formatted_address || "");
          switchToCityGeolocationSorting(setSort, latitude, longitude);
        }
      });
    }

    return () => {
      if (autocomplete) {
        window.google.maps.event.clearInstanceListeners(autocomplete);
      }
    };
  }, []);

  // const handleAutocompleteChange = (event) => {
  //   setAutocompleteValue(event.target.value);
  // };

  const handleSwitchChange = (event) => {
    if (event.target.checked) {
      setLocationCheckboxValue(true);
      switchToUserGeolocationSorting(setSort);
    } else {
      setLocationCheckboxValue(false);
      disableGeolocationSorting(setSort);
    }
    setAutocompleteValue("");
  };

  return (
    <fieldset className="sui-facet">
      <legend className="sui-facet__title">Ort</legend>
      <div className="sui-multi-checkbox-facet">
        <label className="sui-multi-checkbox-facet__option-label">
          <div
            className="sui-multi-checkbox-facet__option-input-wrapper"
            style={{ marginTop: "10px" }}
          >
            <input
              className="sui-multi-checkbox-facet__checkbox"
              type="checkbox"
              checked={locationCheckboxValue}
              onChange={handleSwitchChange}
            />
            <span className="sui-multi-checkbox-facet__input-text">
              Ihren aktuellen Standort nutzen
            </span>
          </div>
        </label>
      </div>

      {locationCheckboxValue === false && (
        <input
          ref={autocompleteRef}
          type="text"
          placeholder="Stadt oder PLZ"
          style={{
            width: "100%",
            padding: "10px",
            margin: "10px",
            marginLeft: "0px",
            borderRadius: "5px",
            border: "1px solid #ccc",
          }}
          // value={autocompleteValue}
          // onChange={handleAutocompleteChange}
        />
      )}
    </fieldset>
  );
};
