import logo from "./logo.svg";
import "./App.css";
import SearchClient from "./SearchClient";

function App({
  searchResultVariant,
  displaySearch,
  displayFilter,
  displayPagingInfo,
  displayShowMore,
  batchSize,
  askForLocation,
  filterType,
  filterValue,
  displayProduct,
}) {
  return (
    <div className={`App ${!displayFilter ? "no-sidebar" : ""}`}>
      <SearchClient
        config={{}}
        searchResultVariant={searchResultVariant}
        displaySearch={displaySearch}
        displayFilter={displayFilter}
        displayPagingInfo={displayPagingInfo}
        displayShowMore={displayShowMore}
        batchSize={batchSize}
        askForLocation={askForLocation}
        filterType={filterType}
        filterValue={filterValue}
        displayProduct={displayProduct}
      ></SearchClient>
    </div>
  );
}

export default App;
