import React, { useEffect } from "react";
import "@elastic/eui/dist/eui_theme_light.css";
import ElasticSearchAPIConnector from "@elastic/search-ui-elasticsearch-connector";
import {
  ErrorBoundary,
  SearchProvider,
  SearchBox,
  Results,
  PagingInfo,
  WithSearch,
} from "@elastic/react-search-ui";
import { Layout } from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import { SearchResultBox } from "./SearchResultBox";
import { SearchResultTableEntry } from "./SearchResultTableEntry";
import { Filter } from "./Filter";
import dayjs from "dayjs";

export default function App({
  searchResultVariant,
  displaySearch,
  displayFilter,
  displayPagingInfo,
  displayShowMore,
  batchSize,
  askForLocation,
  filterType,
  filterValue,
  displayProduct,
}) {
  const maxNumberOfSearchResults = displayProduct ? 10000 : 1000;

  const [geolocationSortingObject, setGeolocationSortingObject] =
    React.useState({ mode: "none", latitude: null, longitude: null });
  const geolocationRef = React.useRef(geolocationSortingObject);

  useEffect(() => {
    geolocationRef.current = geolocationSortingObject;
  }, [geolocationSortingObject]);

  const connector = new ElasticSearchAPIConnector(
    {
      host: "https://6f475a5171b3425fbbab31182fc77266.us-central1.gcp.cloud.es.io:443",
      index: "mi",
      apiKey: "VFlUa041QUJ2UXV4Ykp4T0xpal86aWxRelZDSkZULWlNby1NaG9hUVY0UQ==",
    },
    (body) => {
      let updatedBody = body;

      const currentGeolocationSortingObject = geolocationRef.current;
      if (
        currentGeolocationSortingObject.mode !== "none" &&
        currentGeolocationSortingObject.latitude &&
        currentGeolocationSortingObject.longitude
      ) {
        const newBoolQuery = {
          bool: {
            should: [
              {
                geo_distance: {
                  distance: "300km",
                  coordinates: `${currentGeolocationSortingObject.latitude},${currentGeolocationSortingObject.longitude}`,
                },
              },
              {
                bool: {
                  must: [
                    {
                      bool: {
                        must_not: {
                          exists: {
                            field: "coordinates",
                          },
                        },
                      },
                    },
                    {
                      match: {
                        location: "ONLINE",
                      },
                    },
                  ],
                },
              },
            ],
          },
        };

        // Überprüfen, ob bereits eine bool-Query existiert
        if (updatedBody.post_filter && updatedBody.post_filter.bool) {
          // Existierende should- und must_not-Bedingungen erweitern
          updatedBody.post_filter.bool.should = updatedBody.post_filter.bool
            .should
            ? updatedBody.post_filter.bool.should.concat(
                newBoolQuery.bool.should
              )
            : newBoolQuery.bool.should;
          updatedBody.post_filter.bool.must_not = updatedBody.post_filter.bool
            .must_not
            ? updatedBody.post_filter.bool.must_not.concat(
                newBoolQuery.bool.must_not
              )
            : newBoolQuery.bool.must_not;
        } else {
          // Neue bool-Query einfügen, wenn keine existiert
          updatedBody.post_filter = newBoolQuery;
        }
      }

      if (displayProduct) {
        return {
          ...updatedBody,
          // todo: collapse by kurzbez, as soon as kurzbez is a keyword field in elastic
          // collapse: {
          //   field: "kurzbez",
          // },
          collapse: {
            field: "duration",
          },
        };
      } else {
        return {
          ...updatedBody,
        };
      }
    }
  );

  const [config, setConfig] = React.useState({
    debug: false,
    alwaysSearchOnInitialLoad: true,
    apiConnector: connector,
    hasA11yNotifications: false,
    trackUrlState: false,
    searchQuery: {
      resultsPerPage: 10,
      filters: [],
      search_fields: {
        kurzbez: {
          weight: 3,
        },
        // title: {
        //   weight: 5,
        // },
        // location: {
        //   weight: 1,
        // },
      },
      result_fields: {
        sku: { raw: {} },
        title: { raw: {} },
        location: { raw: {} },
        coordinates: { raw: {} },
        language: { raw: {} },
        start_date: { raw: {} },
        end_date: { raw: {} },
        url: { raw: {} },
        gebuehr: { raw: {} },
        kurzbez: { raw: {} },
        duration: { raw: {} },
        domain: { raw: {} },
        kurs_art: { raw: {} },
        availability: { raw: {} },
        guaranteed: { raw: {} },
        basic: { raw: {} },
        advanced: { raw: {} },
        expert: { raw: {} },
        bundesland: { raw: {} },
        categories: { raw: {} },
        zip_city: { raw: {} },
        target_group: { raw: {} },
        real_estate: { raw: {} },
      },
      disjunctiveFacets: [],
      facets: {
        duration: { type: "value", size: 30 },
        // availability: { type: "value", size: 30 },
        basic: { type: "value", size: 30 },
        advanced: { type: "value", size: 30 },
        expert: { type: "value", size: 30 },
        kurs_art: { type: "value", size: 30 },
        guaranteed: { type: "value", size: 30 },
        categories: { type: "value", size: 30 },
        // location: { type: "value", size: 30 },
      },
    },
    autocompleteQuery: {
      results: {
        search_fields: {
          "kurzbez.suggest": { raw: {} },
          "title.suggest": { raw: {} },
        },
        resultsPerPage: 5,
        result_fields: {
          kurzbez: { raw: {} },
          title: {
            snippet: {
              size: 100,
              fallback: true,
            },
          },
        },
      },
      suggestions: {
        types: {
          documents: { fields: "kurzbez" },
        },
        size: 4,
      },
    },
  });

  const [isLoading, setIsLoading] = React.useState(true);
  const [isInit, setIsInit] = React.useState(false);
  const [filterIsInit, setFilterIsInit] = React.useState(false);
  const [wasSearched, setWasSearched] = React.useState(false);
  const [locationCheckboxValue, setLocationCheckboxValue] =
    React.useState(false);
  const [autocompleteValue, setAutocompleteValue] = React.useState("");
  const [startDate, setStartDate] = React.useState("");
  const [selectedLevel, setSelectedLevel] = React.useState("");
  const [selectedAvailability, setSelectedAvailability] = React.useState(false);
  const [selectedGuarantee, setSelectedGuarantee] = React.useState(false);
  const [selectedIndividualTraining, setSelectedIndividualTraining] =
    React.useState(false);
  const [selectedGroupTraining, setSelectedGroupTraining] =
    React.useState(false);

  const [numberOfVisibleSearchResults, setNumberOfVisibleSearchResults] =
    React.useState(batchSize);
  const [totalNumberOfSearchResults, setTotalNumberOfSearchResults] =
    React.useState(0);

  const updateCenterAndFacets = (latitude, longitude) => {
    const newConfig = {
      ...config,
      searchQuery: {
        ...config.searchQuery,
        facets: {
          ...config.searchQuery.facets,
          coordinates: {
            ...config.searchQuery.facets.coordinates,
            center: `${latitude},${longitude}`, // dynamically update the center
          },
        },
      },
    };
    setConfig(newConfig);
  };

  const loadGoogleApis = () => {
    if (
      searchResultVariant === "table" ||
      searchResultVariant === "table-to-cart" ||
      displayFilter === true
    ) {
      if (!window.google) {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDNKchdGZGUalNMFAzrbscAm1T7HrMLbCQ&libraries=places`;
        script.async = true;
        document.head.appendChild(script);
      }
    }
  };

  const applySkuFilter = (skuArray) => {
    if (filterType === "skus") {
      var skuArray = JSON.parse(filterValue);
      console.log("skuArray:");
      console.log(skuArray);
      config.searchQuery.filters.push({
        field: "sku",
        values: skuArray,
        type: "any",
      });
      updateConfig(config);
    }
  };

  const loadMore = (event) => {
    event.preventDefault();
    const overlayContent = document.querySelector(
      ".header-search-overlay-content"
    );
    const currentWindowScroll = window.scrollY;
    const currentDivScroll = overlayContent ? overlayContent.scrollTop : 0;
    setNumberOfVisibleSearchResults((prev) => prev + batchSize);
    setTimeout(() => {
      window.scrollTo(0, currentWindowScroll);
      if (overlayContent) {
        overlayContent.scrollTop = currentDivScroll;
      }
    }, 0);
  };

  const scrollToHash = () => {
    const hash = window.location.hash;
    if (hash) {
      const decodedHash = decodeURIComponent(hash);
      const element = document.querySelector(decodedHash);
      if (element) {
        const offset = window.innerHeight * 0.2;
        const elementPosition =
          element.getBoundingClientRect().top + window.pageYOffset - offset;
        window.scrollTo({
          top: elementPosition,
          behavior: "smooth",
        });
        const button = element.querySelector("a.button-small-gray");
        if (button) {
          button.click();
        }
      }
    }
  };

  const init = () => {
    setIsLoading(true);
    loadGoogleApis();
    applySkuFilter();
    // if (displayFilter) {
    //   switchToUserGeolocationSorting();
    // }
    setIsLoading(false);
  };

  const switchToUserGeolocationSorting = (setSort) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        updateSortingGeolocation(setSort, {
          mode: "user",
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    }
  };

  const switchToCityGeolocationSorting = (setSort, latitude, longitude) => {
    updateSortingGeolocation(setSort, {
      mode: "city",
      latitude: latitude,
      longitude: longitude,
    });
  };

  const disableGeolocationSorting = (setSort) => {
    updateSortingGeolocation(setSort, {
      mode: "none",
      latitude: null,
      longitude: null,
    });
  };

  const updateSortingGeolocation = (setSort, newGeolocationSortingObject) => {
    setGeolocationSortingObject(newGeolocationSortingObject);
  };

  const updateConfig = (newConfig) => {
    setConfig({ ...newConfig });
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (filterType === "skus" && wasSearched && !isInit) {
      const timeoutId = setTimeout(() => {
        console.log("scroll to hash");
        scrollToHash();
        setIsInit(true);
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [wasSearched]);

  return isLoading ? (
    <></>
  ) : (
    <SearchProvider
      // key={`search-provider-${searchProviderKey}`}
      config={{
        ...config,
        initialState: {
          resultsPerPage: maxNumberOfSearchResults,
        },
      }}
    >
      <WithSearch
        mapContextToProps={({
          results,
          wasSearched,
          pagingEnd,
          searchTerm,
          setSearchTerm,
          addFilter,
          filters,
          removeFilter,
          setFilter,
          searchState,
          setSearchState,
          setSort,
        }) => ({
          results,
          wasSearched,
          pagingEnd,
          searchTerm,
          setSearchTerm,
          addFilter,
          filters,
          removeFilter,
          setFilter,
          searchState,
          setSearchState,
          setSort,
        })}
      >
        {({
          results,
          wasSearched,
          pagingEnd,
          searchTerm,
          setSearchTerm,
          addFilter,
          filters,
          removeFilter,
          setFilter,
          searchState,
          setSearchState,
          setSort,
        }) => {
          setWasSearched(wasSearched);
          setTotalNumberOfSearchResults(
            results.filter(
              (r) => r._meta.rawHit._score > 0 || searchTerm === ""
            ).length
          );

          return (
            <div className="App">
              <ErrorBoundary>
                <Layout
                  header={
                    displaySearch && (
                      <SearchBox
                        autocompleteMinimumCharacters={2}
                        searchAsYouType={true}
                        debounceLength={0}
                        shouldClearFilters={false}
                        inputView={({ getInputProps, getButtonProps }) => (
                          <>
                            <div className="sui-search-box__wrapper">
                              <input
                                {...getInputProps({
                                  placeholder: "Suche",
                                  autoFocus: true,
                                })}
                              />
                            </div>
                            <input
                              {...getButtonProps({
                                "data-custom-attr": "some value",
                              })}
                              value="SEMINAR FINDEN"
                            />
                          </>
                        )}
                      />
                    )
                  }
                  sideContent={
                    displayFilter && (
                      <Filter
                        geolocationSortingObject={geolocationSortingObject}
                        switchToUserGeolocationSorting={
                          switchToUserGeolocationSorting
                        }
                        switchToCityGeolocationSorting={
                          switchToCityGeolocationSorting
                        }
                        disableGeolocationSorting={disableGeolocationSorting}
                        locationCheckboxValue={locationCheckboxValue}
                        setLocationCheckboxValue={setLocationCheckboxValue}
                        autocompleteValue={autocompleteValue}
                        setAutocompleteValue={setAutocompleteValue}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        addFilter={addFilter}
                        filters={filters}
                        removeFilter={removeFilter}
                        selectedLevel={selectedLevel}
                        setSelectedLevel={setSelectedLevel}
                        selectedAvailability={selectedAvailability}
                        setSelectedAvailability={setSelectedAvailability}
                        selectedGuarantee={selectedGuarantee}
                        setSelectedGuarantee={setSelectedGuarantee}
                        filterType={filterType}
                        filterValue={filterValue}
                        setFilter={setFilter}
                        filterIsInit={filterIsInit}
                        setFilterIsInit={setFilterIsInit}
                        searchState={searchState}
                        setSearchState={setSearchState}
                        setSort={setSort}
                        setSearchTerm={setSearchTerm}
                        selectedIndividualTraining={selectedIndividualTraining}
                        setSelectedIndividualTraining={
                          setSelectedIndividualTraining
                        }
                        selectedGroupTraining={selectedGroupTraining}
                        setSelectedGroupTraining={setSelectedGroupTraining}
                        updateCenterAndFacets={updateCenterAndFacets}
                      />
                    )
                  }
                  bodyContent={
                    <Results
                      id={`result-${Math.random().toString(36).substr(2, 9)}`}
                      titleField="kurzbez"
                      urlField="url"
                      thumbnailField=""
                      shouldTrackClickThrough={true}
                      resultView={({ result }) => {
                        const score = result._meta.rawHit._score;
                        if ((!score || score === 0) && searchTerm !== "")
                          return;

                        // const index = results
                        //   .filter(
                        //     (r) =>
                        //       r._meta.rawHit._score > 0 || searchTerm === ""
                        //   )
                        //   .findIndex((item) => item.id.raw === result.id.raw);

                        const index = results.findIndex(
                          (item) => item.id.raw === result.id.raw
                        );

                        return index < numberOfVisibleSearchResults ? (
                          searchResultVariant === "box" ? (
                            <SearchResultBox
                              result={result}
                              displayProduct={displayProduct}
                            />
                          ) : (
                            <SearchResultTableEntry
                              result={result}
                              displayProduct={displayProduct}
                              linkToCart={
                                searchResultVariant === "table-to-cart"
                              }
                            />
                          )
                        ) : null;
                      }}
                    />
                  }
                  bodyHeader={
                    displayPagingInfo && (
                      <React.Fragment>
                        {wasSearched && <PagingInfo />}
                      </React.Fragment>
                    )
                  }
                  bodyFooter={
                    displayShowMore &&
                    numberOfVisibleSearchResults <
                      totalNumberOfSearchResults && (
                      <button
                        onClick={loadMore}
                        className="button-small-black"
                        style={{
                          maxWidth: "fit-content",
                          marginTop: "50px",
                          marginBottom: "50px",
                        }}
                      >
                        <p>MEHR LADEN</p>
                        <img
                          style={{
                            height: "12.015px",
                            width: "10.498px",
                            transform: "rotate(90deg)",
                            cursor: "pointer",
                          }}
                          src="https://manager-institut.hppycdng.dev/wp-content/themes/manager-institut/theme/images/react-kufer-search/arrow-white.png"
                          alt="open"
                        />
                      </button>
                    )
                  }
                />
              </ErrorBoundary>
            </div>
          );
        }}
      </WithSearch>
    </SearchProvider>
  );
}
