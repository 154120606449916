import { set } from "lodash";
import React, { useEffect, useRef, useState } from "react";

export const MiscellaneousSelection = ({
  filters,
  addFilter,
  removeFilter,
  setFilter,
  selectedGuarantee,
  setSelectedGuarantee,
  selectedAvailability,
  setSelectedAvailability,
}) => {
  return (
    <fieldset className="sui-facet">
      <legend className="sui-facet__title">Sonstiges</legend>
      <div className="sui-multi-checkbox-facet">
        <div style={{ display: "flex", width: "100%" }}>
          <button
            className={
              selectedAvailability === true
                ? "filterSelectionButtonSelected"
                : "filterSelectionButton"
            }
            onClick={(event) => {
              // removeFilter("availability", "1");
              if (selectedAvailability) {
                setSelectedAvailability(false);
              } else {
                setSelectedAvailability(true);
                setFilter("availability", [1, 2], "none");
                // addFilter("availability", "1");
                // addFilter("availability", ["1", "2"], "any");
                // addFilter("availability", ["1", "2"], "any");
                // addFilter([
                //   {
                //     field: "availability",
                //     values: ["1", "2"],
                //     type: "any",
                //   },
                // ]);
              }
            }}
          >
            <p>Nur verfügbare Seminare</p>
          </button>
          <button
            className={
              selectedGuarantee === true
                ? "filterSelectionButtonSelected"
                : "filterSelectionButton"
            }
            onClick={(event) => {
              removeFilter("guaranteed", true);
              if (selectedGuarantee) {
                setSelectedGuarantee(false);
              } else {
                setSelectedGuarantee(true);
                addFilter("guaranteed", true);
              }
            }}
          >
            <p>Nur Garantie-Seminare</p>
          </button>
        </div>
      </div>
    </fieldset>
  );
};
