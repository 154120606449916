import React from "react";
import {
  getAvailabilityIcon,
  getGuaranteeIcon,
  getLevelIcon,
} from "./SearchResultController";

export const SearchResultTableEntry = ({
  result,
  displayProduct,
  linkToCart,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [addToCartLoading, setAddToCartLoading] = React.useState(false);

  function formatDate(dateString) {
    // Split the date and time
    const [datePart] = dateString.split(" ");

    // Split the date part into year, month, and day
    const [year, month, day] = datePart.split("-");

    // Format the date in DD.MM.YYYY format
    return `${day}.${month}.${year}`;
  }

  function getDatesBetween(startDate, endDate) {
    const dates = [];
    let currentDate = new Date(startDate);
    const end = new Date(endDate);

    while (currentDate <= end) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      dates.push(currentDate.toLocaleDateString("de-DE", options));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  }

  function addToCart(event) {
    event.preventDefault();
    setAddToCartLoading(true);
    fetch(
      `/programm/kw/bereich/kategorien/warenkorb-hinzufuegen/${result.sku.raw}/`
    )
      .then((response) => {
        // Überprüfen Sie den Status der Antwort
        if (response.ok) {
          console.log("Produkt erfolgreich zum Warenkorb hinzugefügt.");
          window.location.href = "/kw-warenkorb/";
        } else {
          console.error(
            "Es gab ein Problem beim Hinzufügen des Produkts zum Warenkorb."
          );
        }
      })
      .catch((error) => {
        console.error("Fehler beim Hinzufügen zum Warenkorb:", error);
      });
  }

  function openAccordion(e) {
    setIsOpen(!isOpen); // Zustand toggeln
  }

  React.useEffect(() => {
    if (isOpen && result.coordinates.raw) {
      const map = new window.google.maps.Map(
        document.getElementById(`map-${result.sku.raw}`),
        {
          zoom: 12,
          center: {
            lat: result.coordinates.raw.lat,
            lng: result.coordinates.raw.lon,
          },
        }
      );
      new window.google.maps.Marker({
        position: {
          lat: result.coordinates.raw.lat,
          lng: result.coordinates.raw.lon,
        },
        map: map,
      });
    }
  }, [isOpen, result.sku.raw]);

  return (
    <div
      id={result.sku.raw}
      className="p-0 mi-accordions-accordion"
      style={{ maxWidth: "100%", padding: "0px" }}
    >
      <div
        className="content-single-product-container-right-seminar-container"
        style={{ height: "auto" }}
      >
        <div className="content-single-product-container-right-seminar-container-content">
          <div className="content-single-product-container-right-seminar-container-content-left">
            {!displayProduct ? (
              <div className="content-single-product-container-right-seminar-container-content-left-left">
                {getAvailabilityIcon(result.availability.raw)}
              </div>
            ) : (
              <></>
            )}

            <div className="content-single-product-container-right-seminar-container-content-left-right">
              <div className="content-single-product-container-right-seminar-container-content-left-right-content">
                <img
                  className="mi-kufer-search-result-level"
                  src="https://manager-institut.hppycdng.dev/wp-content/themes/manager-institut/theme/images/react-kufer-search/location.png"
                  alt="open"
                />
                {result.location.raw}
              </div>
              <div className="content-single-product-container-right-seminar-container-content-left-right-content">
                <img
                  className="mi-kufer-search-result-level"
                  src="https://manager-institut.hppycdng.dev/wp-content/themes/manager-institut/theme/images/react-kufer-search/calendar.png"
                  alt="open"
                />
                {formatDate(result.start_date.raw)}
              </div>
              <a
                className="button-small-gray"
                id="open-menu-button-<?php echo $variation_id; ?>"
                data-variation-id="<?php echo $variation_id; ?>"
                onClick={(e) => openAccordion(e)}
              >
                <p>DETAILS</p>
                <img
                  src="https://manager-institut.hppycdng.dev/wp-content/themes/manager-institut/theme/images/react-kufer-search/arrow-black.png"
                  style={{
                    transform: isOpen ? "rotate(90deg)" : "none",
                  }}
                  alt="open"
                />
              </a>
            </div>
          </div>
          <div className="content-single-product-container-right-seminar-container-content-right">
            {result.availability.raw !== 2 ? (
              <>
                {linkToCart ? (
                  <button
                    className="button-large-green mi-offer-button"
                    style={{
                      border: "none",
                      cursor: "pointer",
                      backgroundColor: addToCartLoading ? "#97b571" : "#d4ff9d",
                    }}
                    onClick={addToCart}
                  >
                    <p>IN DEN WARENKORB</p>
                    <img
                      style={{
                        height: "100%",
                        width: "auto",
                      }}
                      src="https://manager-institut.hppycdng.dev/wp-content/themes/manager-institut/theme/images/react-kufer-search/cart.png"
                      alt="open"
                    />
                  </button>
                ) : (
                  <a
                    className="button-large-green mi-offer-button"
                    style={{ width: "100%" }}
                    href={
                      result.url.raw ? result.url.raw.replace("//#", "/#") : ""
                    }
                  >
                    <p>SEMINAR ENTDECKEN</p>
                    <img
                      style={{
                        height: "12.015px",
                        width: "10.498px",
                        transform: "rotate(-90deg)",
                      }}
                      src="https://manager-institut.hppycdng.dev/wp-content/themes/manager-institut/theme/images/react-kufer-search/long-arrow-down-black.png"
                      alt="open"
                    />
                  </a>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div
        className={`w-100 ${
          isOpen
            ? "mi-accordions-accordion-text-opened"
            : "mi-accordions-accordion-text-closed"
        }`}
        style={{ borderTop: "1px solid #f0f0f0" }}
      >
        <div className="mi-accordions-accordion-container-left">
          <div className="variant-details-container">
            <div className="variant-details-price-container">
              <div className="variant-details-heading">Preis</div>
              <div className="variant-details-heading-price">
                {parseFloat(result.gebuehr.raw).toFixed(2)} €
              </div>
              <div className="variant-details-description-price">
                {parseFloat(result.gebuehr.raw).toFixed(2)} € INKL. 19% MWST.
                MIT GELD ZURÜCK GARANTIE
              </div>
            </div>
            <div className="variant-details-time-container">
              <div style={{ display: "flex", gap: "5px" }}>
                <div className="variant-details-heading">Zeitplan</div>
                <div className="variant-sku">{result.sku.raw}</div>
              </div>
              <div className="mi-intro-seminars-info">
                <img
                  className="p-0 m-0"
                  src="https://manager-institut.hppycdng.dev/wp-content/themes/manager-institut/theme/images/react-kufer-search/time.png"
                  alt="Background"
                />
                <div className="mi-intro-seminars-info-text">
                  {result.duration.raw}
                </div>
              </div>

              {getDatesBetween(result.start_date.raw, result.end_date.raw).map(
                (date, index) => (
                  <div key={index}>
                    <div
                      className="mi-intro-seminars-info"
                      style={{ marginTop: "5px" }}
                    >
                      <div className="mi-intro-seminars-info-text">{date}</div>
                    </div>
                    <div className="mi-intro-seminars-info">
                      <div className="mi-intro-seminars-info-text">
                        {index === 0 ? "10 Uhr - 17 Uhr" : "9 Uhr - 17 Uhr"}
                      </div>
                    </div>
                  </div>
                )
              )}

              <div
                className="variant-details-heading"
                style={{ marginTop: "15px" }}
              >
                Das Paket beinhaltet
              </div>
              <div className="mi-intro-seminars-info">
                <div className="mi-intro-seminars-info-text">
                  Ein Mitagessen in der Gruppe pro ganzem Seminartag
                </div>
              </div>
              <div className="mi-intro-seminars-info">
                <div className="mi-intro-seminars-info-text">
                  Snacks und Getränke in der Pause
                </div>
              </div>
              <div className="mi-intro-seminars-info">
                <div className="mi-intro-seminars-info-text">
                  Umfängeliche Arbeitsunterlagen
                </div>
              </div>
            </div>
            <div className="variant-details-location-container">
              {/* <?php if ($aussenst !== 'Online') : ?> */}
              {result.coordinates.raw && (
                <>
                  <div className="variant-details-heading">
                    Veranstaltungsort
                  </div>
                  <div
                    id={`map-${result.sku.raw}`}
                    style={{ width: "100%", height: "300px" }}
                  ></div>
                  <div style={{ textAlign: "left" }}>
                    {result.real_estate.raw}
                  </div>
                  <div className="variant-details-location-button-container">
                    <div></div>
                    <a
                      className="button-small-black"
                      href={`https://www.google.com/maps/search/?api=1&query=${result.coordinates.raw.lat},${result.coordinates.raw.lon}`}
                      style={{ maxWidth: "fit-content" }}
                      id={`route-button-${result.sku.raw}`}
                      target="_blank"
                    >
                      <p>ROUTE</p>
                      <img
                        style={{
                          height: "12.015px",
                          width: "10.498px",
                        }}
                        src="https://manager-institut.hppycdng.dev/wp-content/themes/manager-institut/theme/images/react-kufer-search/arrow-white.png"
                        alt="open"
                      />
                    </a>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
