import { set } from "lodash";
import React, { useEffect, useRef, useState } from "react";

export const GroupSelection = ({
  filters,
  addFilter,
  removeFilter,
  setFilter,
  selectedIndividualTraining,
  setSelectedIndividualTraining,
  selectedGroupTraining,
  setSelectedGroupTraining,
}) => {
  return (
    <fieldset className="sui-facet">
      <legend className="sui-facet__title">Durchführungsformat</legend>
      <div className="sui-multi-checkbox-facet">
        <div style={{ display: "flex", width: "100%" }}>
          <button
            className={
              selectedIndividualTraining === true
                ? "filterSelectionButtonSelected"
                : "filterSelectionButton"
            }
            onClick={(event) => {
              if (selectedIndividualTraining) {
                setSelectedIndividualTraining(false);
              } else {
                if (selectedGroupTraining) {
                  setSelectedGroupTraining(false);
                }
                setSelectedIndividualTraining(true);
              }
            }}
          >
            <p>Individualtraining</p>
          </button>
          <button
            className={
              selectedGroupTraining === true
                ? "filterSelectionButtonSelected"
                : "filterSelectionButton"
            }
            onClick={(event) => {
              if (selectedGroupTraining) {
                setSelectedGroupTraining(false);
              } else {
                if (selectedIndividualTraining) {
                  setSelectedIndividualTraining(false);
                }
                setSelectedGroupTraining(true);
              }
            }}
          >
            <p>Gruppentraining</p>
          </button>
        </div>
      </div>
    </fieldset>
  );
};
