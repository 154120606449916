export const getAvailabilityIcon = (availability) => {
  return (
    <img
      className="mi-kufer-search-result-ampel"
      src={`https://manager-institut.hppycdng.dev/wp-content/themes/manager-institut/theme/images/react-kufer-search/ampel-${availability}.png`}
      alt="open"
    />
  );
};

export const getGuaranteeIcon = (guarantee) => {
  return guarantee ? (
    <div className="mi-kufer-search-result-guarantee">GARANTIE</div>
  ) : (
    ""
  );
};

export const getLevelIcon = (level) => {
  return level === "0" ? null : (
    <img
      className="mi-kufer-search-result-level"
      src={`https://manager-institut.hppycdng.dev/wp-content/themes/manager-institut/theme/images/react-kufer-search/level-${level}.png`}
      alt="open"
    />
  );
};
