import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const containers = document.querySelectorAll(
  ".react-elastic-seminar-search-container"
);

containers.forEach((container) => {
  const root = ReactDOM.createRoot(container);

  const searchResultVariant = container.getAttribute(
    "data-searchResultVariant"
  );
  const displaySearch =
    container.getAttribute("data-displaySearch") === "true" ? true : false;
  const displayFilter =
    container.getAttribute("data-displayFilter") === "true" ? true : false;
  const displayPagingInfo =
    container.getAttribute("data-displayPagingInfo") === "true" ? true : false;
  const displayShowMore =
    container.getAttribute("data-displayShowMore") === "true" ? true : false;
  const batchSize = parseInt(container.getAttribute("data-batchSize"), 10);
  const askForLocation =
    container.getAttribute("data-askForLocation") === "true" ? true : false;
  const filterType = container.getAttribute("data-filterType");
  const filterValue = container.getAttribute("data-filterValue");
  const displayProduct =
    container.getAttribute("data-displayProduct") === "true" ? true : false;

  root.render(
    // <React.StrictMode>
    <App
      searchResultVariant={searchResultVariant}
      displaySearch={displaySearch}
      displayFilter={displayFilter}
      displayPagingInfo={displayPagingInfo}
      displayShowMore={displayShowMore}
      batchSize={batchSize}
      askForLocation={askForLocation}
      filterType={filterType}
      filterValue={filterValue}
      displayProduct={displayProduct}
    />
    // </React.StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
